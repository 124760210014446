import React from 'react'
import './Filler.css'

function Filler() {
  return (
    <div className="filler-banner">
     <div className="fillerbanner-text">
        Our missions at Symphony IT is to understand your needs, develop options for how to meet
        those needs,and create secure and simple workflows based on your choices.
        We are here to guide you through modernizing your infrastructure and helping your employees
        with security and workflow playbooks. We want you to be and feel supported in your IT needs & developments.
      </div>
   </div>
  )
}

export default Filler