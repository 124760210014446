import React from 'react'
import Header from '../../components/Header/Header'
import Nav from '../../components/Nav/Nav'
import Footer from '../../components/Footer/Footer'
import './ResSupport.css'
import Calendly from '../../components/Calendly/Calendly'

function ResSupport() {
  return (
    <div>
      <Header />
      <Nav/>
      <div className="rservices" >
        <div className="rheader">
          <h1>RESIDENTIAL SUPPORT</h1>
        </div>

      <div className='rservice-cat-container'>
      <div className='rcat'>
            <h3>HOME NETWORK SUPPORT</h3>

            <div className="rcat-text">
              
              <p> Is your computer network in need of optimization? Are your speeds slower than you would
                like? We can work with you to find out what equipment you need in order to support the
                amount of devices and available bandwidth from your Internet Service Provider</p>
          
            </div>
      </div>
      <div className='rcat'>
            <h3>PC TROUBLESHOOTING & REPAIR</h3>

            <div className="rcat-text">
              
              <p> When your computer has troubles we are the expert to diagnose what is wrong and
                come up with remediation.
                Ask us about any computer issue big or small today!</p>
          
          
            </div>
      </div>
      <div className='rcat'>
            <h3> DATA TRANSFER</h3>

            <div className="rcat-text">
              <p>Whether your disk drive is dying, you jsut want to back up your files or you need to
                move data to a larger storage device,
                we've got you covered with bit-by-bit transfers.</p>


          </div>
          </div>
      <div className='rcat'>
            <h3> VIRUS CLEAN UP</h3>

            <div className="rcat-text">
              <p>Are you concerned about your computer's security? Have you unwittingly downloaded nefarious
                programs? It happens to the best of us, and we are here to help!
                Get a cleanup today and get your computer running faster and smoother!</p>
          </div>
      </div>
        </div>
      </div>
      <Calendly/>
      <Footer/>
    </div>
  )
}

export default ResSupport