import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom'
import './DropdownNav.css'

function DropdownNav() {

  const [open, setOpen] = useState(false);

  let menuRef = useRef();

  useEffect(() => {
    let handler = (e)=>{
      if(!menuRef.current.contains(e.target)){
        setOpen(false);
        console.log(menuRef.current);
      }      
    };

    document.addEventListener("mousedown", handler);
    

    return() =>{
      document.removeEventListener("mousedown", handler);
    }

  });

  return (
    <div className="DropdownNav">
      <div className='menu-container' ref={menuRef}>
        <div className='menu-trigger' onClick={()=>{setOpen(!open)}}>
        </div>

        <div className={`dropdown-menu ${open? 'active' : 'inactive'}`} >
          {/* <h3>Menu</h3> */}
          <ul>
            <li><Link className='dropdown-link' to='/'> Home</Link></li>
            <li> <Link className='dropdown-link' to='/services'> Business Services</Link></li>
            <li>  <Link className='dropdown-link' to='/homesupport'> Residential Support</Link></li>
            <li> <Link className='dropdown-link' to='/itconsulting'>IT Consulting</Link></li>
            <li><Link className='dropdown-link' to='/thedifference'>An Orchestrated Difference</Link></li>
            <li><Link className='dropdown-link' to='/contact'>Contact</Link></li>

          </ul>
        </div>
      </div>
    </div>
  );
}



export default DropdownNav;