import './Header.css'
import logo from './logo.png'

function Header() {
  
    return (
  
      <div className="header" >
      <img className='logo' src={logo} alt='ummm'></img>
        </div>
  
    )
  }
  
  export default Header;