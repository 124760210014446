import React from 'react'
import './About.css'
// import { Link } from 'react-router-dom'

function About() {
  return (
  
    <div className="about-container" >

      <div className='about-title'><h1>Orchestrate Simplify Secure</h1></div>
      </div>

   

  )
}

export default About