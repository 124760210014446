import React from "react";
import axios from "axios";
import { useState } from "react";
import location from './location.png'
import mail from './mail.png'
import phonecall from './phonecall.png'
import './Footer.css'


export default function Contact() {


  const formId = "0t2J8eB0x";
  const formUrl = `https://submit-form.com/${formId}`

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");
  const [result, setResult] = useState("")

  // const [values, setValues] = useState(initialFormValues);
  
  const submitForm = async (e) => {
    e.preventDefault();
    await postSubmission();
    setResult("Music to Our Ears, We'll Be in Touch!");
    setName("");
    setCompany("");
    setEmail("");
    setMessage("");
  };

  
  const postSubmission = async () => {
    const formInfo = {
      name: name,
      email:email,
      message:message,
    };
  
  

    try {
      const res = await axios.post(formUrl, formInfo);
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };



  return (
    <div className="footer">
      <div className="firstcontactbox-container">

        <div className="contact-header">
        <h1>Let's Talk About The Future</h1>
        </div>
        
        <div className="secondcontact-container">
        <div className="contactinfo-container">
          <div><img className='location-icon' src={location} width={20} height={20} alt='ummm'></img>Serving the DMV Area</div>
          <div><img className='phone-icon' src={phonecall} width={20} height={20} alt='ummm'></img>443-252-3005</div>
          <div><img className='email-icon ' src={mail} width={20} height={20} alt='ummm'></img>
            <a href="mailto:help-me@symphony-it.com">help-me@symphony-it.com</a></div>
        </div>


      <div className="contactform-container">
      <div className="title-box">
      <h1>CONTACT US</h1>
      </div>
        <div className="form-container">
          <form id="form" onSubmit={submitForm} className="form-text">
          <div class="form-item">
          <input type="text" name="sender" onChange={(e) => { setName(e.target.value) }} value={name} required/>
          <label>Name</label>
        </div>
        <div class="form-item">
          <input type="text" name="email" onChange={(e) => { setEmail(e.target.value) }} value={email} required/>
          <label>Email</label>
              </div>
              <div class="form-item">
          <input type="text" name="company" onChange={(e) => { setCompany(e.target.value) }} value={company} required/>
          <label>Company</label>
        </div>
        <div class="form-item">
              <textarea class="" name="message" onChange={(e) => { setMessage(e.target.value) }} value={message} required></textarea>
          <label>Message</label>
        </div>
        <button type="submit" class="submit-btn">SEND</button> 
         </form>
        </div>
        <div> <h4>{result}</h4></div>
        </div>
        </div>
        
      </div>
      </div>
  )
}
