import React from 'react'
import Header from '../../components/Header/Header'
import Nav from '../../components/Nav/Nav'
import Footer from '../../components/Footer/Footer'
import './Consulting.css'
import Calendly from '../../components/Calendly/Calendly'

function Consulting() {
  return (
    <div>
      <Header />
      <Nav/>
      <div className="cservices" >
        <div className="cheader">
          <h1>IT CONSULTING</h1>
        </div>

      <div className='cservice-cat-container'>
      <div className='ccat'>      
            <div className="ccat-text">
              
              <p> Does your IT team need enterprise support? Symphony IT is here to help.
                With many years of enterprise experience in infrastructure, mass device
                deployment and management, IdP, IaaS & SaaS, Symphony IT is ready to assist your
                IT department to streamline workflows and systems until they resonate harmoniously.
            </p>
          
              <li>vCIO</li>
              <li>Red2Green Auditing</li>
              <li>Zero Trust Posture Auditing</li>
              <li>On & Off Boarding Management</li>
            </div>
      </div>
      </div>
      </div>
      <Calendly/>
        <Footer />
    </div>
  )
}

export default Consulting