import React from 'react'
import About from '../../components/About/About'
import Header from '../../components/Header/Header'
import Nav from '../../components/Nav/Nav'
import Filler from '../../components/Filler/Filler'
import Services from '../../components/Services/Services'
import Footer from '../../components/Footer/Footer'
import DropdownNav from '../../components/DropdownNav/DropdownNav'

function Home() {
  return (
    <div>
      <Header />
      <Nav />
      <About />
      <Filler />
      <Services />
      <Footer/>
      
    </div>
  )
}

export default Home