import './Services.css'
import React from 'react'
import { PopupModal } from 'react-calendly';

class Services extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }
  
  render() {
    return (
  
      <div className="services" >
        
        <div className="calltoaction-container">
          
        <div className="calendly-container">
              <button className="calendly-button"
                onClick={() => this.setState({ isOpen: true })}
                >
              <h1>Schedule a free consultation to assess your IT needs and we'll orchestrate the perfect plan.</h1>
              </button>
              <PopupModal
                url="https://calendly.com/arbiter-symphony-it/30min"
                pageSettings={this.props.pageSettings}
                utm={this.props.utm}
                prefill={this.props.prefill}
                onModalClose={() => this.setState({ isOpen: false })}
                open={this.state.isOpen}
                rootElement={document.getElementById("root")}
              />
            </div>
          
      </div>
        <div className='service-cat-container'>
        
            <div className='cat'>
              
          <h3>MANAGED IT SERVICES</h3>
     
          <div className="cat-text">
            <p> Do you need a continuous IT Helpdesk for your business? Looking for support to your infrastructure?
              Do you or your employees require help with computer and software issues? Inquire about Managed Services
            </p>
          
            <li>Helpdesk Support for all Platforms: Mac, Linux, Windows</li>
            <li>Mobile Device Management</li>
            <li>Group Policy and Employee Identity Management</li>
            <li>CIO Consulting</li>
            <li>DNS and Email solutions management</li>
            <li>IaaS and SaaS Solutions</li>
            <li>Cloud Services</li>
                </div>
                
          </div>

          
        <div className='cat'>
          <h3>VIRUS CLEAN UP</h3>
          <div className="cat-text">
              
            <p> Is your computer affected by viruses/malware? We can perform a cleanup of your PC.</p>
              
            <li>Virus Cleanup</li>
            <li>Junk File Removal</li>
            <li>Software Cleanup</li>
            <li>OS Restoration/Reinstall</li>
          </div>
        </div>
          
        <div className='cat'>
          <h3>PC DIAGNOSTICS & REPAIR</h3>
          <div className="cat-text">

            <p> Computer running slow? Do you need to make backups of data you care about?
              Contact us for a diagnosis and we will diagnose and repair if you wish!</p>
              
            <li>Hardware Diagnostics</li>
            <li>Software Diagnostics</li>
            <li>Affordable Part Replacement</li>
            <li>Data Recovery</li>
          </div>
        </div>
          
        <div className='cat'>
          <h3> CYBER SECURITY</h3>

          <div className="cat-text">
            <p> The best defense is a strong offense, and this means being proactive.
              We meticulously mitigate against data and traffic disasters with plans of redundancy,
              backups, hot swaps and high availability.We will assess your needs and develop a gameplan
              for securing your environments, devices, and identities.
            </p>
            <li>Endpoint Protection</li>
            <li>Disaster Recovery Plans</li>
            <li>2FA & Account Security Audit</li>
            <li>COMING SOON: Penetration Testing</li>
          </div>
        </div>
        <div className='cat' id='support'>
          <h3>TECHNOLOGIES WE SUPPORT</h3>

          <div className="cat-text">
            <li>Zero Trust & RBAC</li>
            <li>macOS deployment, Apple Business Manager and DEP</li>
            <li>Windows endpoints, Azure, Entra ID, GPO, AD</li>
            <li>Linux/BSD, OpenLDAP</li>
            <li>Okta SSO, Adaptive MFA, Lifecycle Management, Workflows, Identity Governance, Actions</li>
            <li>Duo MFA</li>
            <li>RADIUS 802.1x authentication</li>
            <li>VMWare ESXi</li>
            <li>Hyper-V</li>
            <li>JumpCloud LDAP, Identity Management, Linux/macOS/iOS/Windows MDM</li>
            <li>ProofPoint Secure Email Relay, Adaptive Email Security, DLP</li>
            <li>Amazon Web Services</li>
            <li>Docker</li>
            <li>Kubernetes</li>
            <li>RDS/RDP</li>
              <li>Relational Databases</li>
              <li>Ubiquiti, Cisco, pfsense</li>
              <li>Quickbooks</li>


          </div>
        </div>
    
        </div>
      </div>
    )
  }
}
  
  export default Services;

