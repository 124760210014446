import React from 'react'
import './Nav.css'
import { Link } from 'react-router-dom'
import DropdownNav from '../DropdownNav/DropdownNav'


function Nav() {

  return (
    
    <nav className='navbar'>
      <div className="mobile-nav">
      <DropdownNav />
      </div>
      <div className="desk-nav">
      <ul className='nav-menu'>
      <li className='nav-item'><Link className='nav-link' to='/'>Home</Link></li>
      <li className='nav-item'><Link className='nav-link' to='/services'>Business Services</Link>
          <ul>
          <li className='secondarynav-item'><Link to='/services'><a>Managed IT Services</a></Link></li>
            <li className='secondarynav-item'><Link to='/services'><a>Virtual Help Desk</a></Link></li>
            <li><Link to='/services'><a>Compliance & Security</a></Link></li>
          </ul>
        </li>
        
          <li className='nav-item'><Link className='nav-link' to='/homesupport'>Residential Support</Link>
            <ul>
            <li className='secondarynav-item'><Link to='/homesupport'><a href="#">Home Network Support</a></Link></li>
            <li className='secondarynav-item'><Link to='/homesupport'><a href="#">PC Troubleshooting & Repair</a></Link></li>
            <li className='secondarynav-item'><Link to='/homesupport'><a href="#">Data Transfer</a></Link></li>
            <li className='secondarynav-item'><Link to='/homesupport'><a href="#">Virus Clean Up</a></Link></li>
            </ul>
        </li>

        <li className='nav-item'><Link className='nav-link' to='/itconsulting'>IT Consulting</Link></li>
        
        <li className='nav-item'><Link className='nav-link' to='/thedifference'>An Orchestrated Difference</Link>
        </li>

        <li className='nav-item'><Link className='nav-link' to='/contact'>Contact</Link>
        <ul>
              <li className='secondarynav-item'><a href="https://symphony.it.com/contact#freeconsult">Free Consultation</a></li>
              <li className='secondarynav-item'><a href="#"> IT Inquiries</a></li>
            </ul>
        </li>
      </ul>
      </div>
  </nav>
  )
}

export default Nav