import React from 'react'
import Header from '../../components/Header/Header'
import Nav from '../../components/Nav/Nav'
import Footer from '../../components/Footer/Footer'
import './BusinessServices.css'

function BusinessServices() {
  return (
    <div>
      <Header />
      <Nav />
      <div className="bservices" >
        <div className="bheader">
          <h1>BUSINESS SERVICES</h1>
        </div>

      <div className='bservice-cat-container'>
      <div className='bcat'>
            <h3>MANAGED IT SERVICES</h3>
           
            <div className="bcat-text">
              
            <p> Do you need a continuous IT Helpdesk for your business? Looking for support to your infrastructure? 
              Do you or your employees require help with computer and software issues? Inquire about Managed Services
            </p>
          
              <li>Helpdesk Support for all Platforms: Mac, Linux, Windows</li>
              <li>Mobile Device Management</li>
              <li>Group Policy and Employee Identity Management</li>
              <li>CIO Consulting</li>
              <li>DNS and Email solutions management</li>
              <li>IaaS and SaaS Solutions</li>
              <li>Backup & Disaster Recovery Plans</li>
          
            </div>
      </div>
      <div className='bcat'>
            <h3>VIRTUAL HELP DESK</h3>
           
            <div className="bcat-text">
              
            <p> Do you need a continuous IT Helpdesk for your business? Looking for support to your infrastructure? 
              Do you or your employees require help with computer and software issues? Inquire about Managed Services
            </p>
          
              <li>Helpdesk Support for all Platforms: Mac, Linux, Windows</li>
              <li>Mobile Device Management</li>
              <li>Group Policy and Employee Identity Management</li>
              <li>CIO Consulting</li>
              <li>DNS and Email solutions management</li>
              <li>IaaS and SaaS Solutions</li>
              <li>Backup & Disaster Recovery Plans</li>
          
            </div>
      </div>
      <div className='bcat'>
            <h3> ONBOARDING SPECIALIST</h3>

            <div className="bcat-text">
              <p>Streamline on & offboarding with our identity & access
                controls managment expert. We will develop & provide documentation,
                training and custom workflows based on your tools and technologies to
                make onboarding as efficient as possible.
              </p>
              <li>On & Offboarding Automation</li>
              <li>Lifecyle Management</li>
              <li>Employee & Access Control Management</li>
              <li>Contractor & Partner Management</li>
              


          </div>
          </div>
      <div className='bcat'>
            <h3> CYBER SECURITY</h3>

            <div className="bcat-text">
              <p>Is cyber security a concern for you? Securing your digital assets can be daunting,
                especially if you need to meet compliance. We can help you to secure your digital assets and workflows.
                Let us help you to implement 2FA on your accounts, create disaster recovery plans, and deploy and maintain Anti-Virus protection.
              </p>
              <li>Endpoint Protection</li>
              <li>Disaster Recovery Plans</li>
              <li>2FA & Account Security Audit</li>
              <li>Patch Management</li>
              <li>COMING SOON: Penetration Testing</li>
          </div>
      </div>
      <div className='bcat'>
            <h3> RED TO GREEN AUDITING</h3>

            <div className="bcat-text">
              <p>DETAILS COMING SOON
              </p>
              <li>Endpoint Protection</li>
              <li>Disaster Recovery Plans</li>
              <li>2FA & Account Security Audit</li>
          

          </div>
          </div>
          <div className='bcat'>
            <h3> CLOUD SERVICES</h3>

            <div className="bcat-text">
              <p>We provide archtecting, administration and support of Cloud,
                Hybrid Cloud, Public Cloud, IaaS, SaaS and everything in-between.
                Tell us your needs and we will ensure they are met with flying colors.
              </p>
              <li>Okta</li>
              <li>Office 360</li>
              <li>Linode</li>
              <li>Azure</li>

          </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default BusinessServices