import React from 'react'
import './Calendly.css'
import { PopupModal } from 'react-calendly';



class Calendly extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }
  
  render() {
    return (
  
      <div className="calendly-component" >
        
        <div className="calltoaction-container">
          
          <div className="calendly-container">
            <button className="calendly-button"
              onClick={() => this.setState({ isOpen: true })}
            >
              <h1>Schedule a free consultation to assess your IT needs and we'll orchestrate the perfect plan.</h1>
            </button>
            <PopupModal
              url="https://calendly.com/arbiter-symphony-it/30min"
              pageSettings={this.props.pageSettings}
              utm={this.props.utm}
              prefill={this.props.prefill}
              onModalClose={() => this.setState({ isOpen: false })}
              open={this.state.isOpen}
              rootElement={document.getElementById("root")}
            />
          </div>
        </div>
      </div>
    )
  }
}
      

export default Calendly