import './App.css';
import React from 'react';
import Home from './screens/Home/Home';
import { Route, Routes } from 'react-router-dom';
import BusinessServices from './screens/BusinessServices/BusinessServices';
import ResSupport from './screens/ResidentialSupport/ResSupport'
import Consulting from './screens/Consulting/Consulting';
import TheDif from './screens/TheDifference/TheDif';
import Contact from './screens/Contact/Contact';

function App() {
  return (
    <div className="App">
          <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/services' element={<BusinessServices />} />
        <Route path='homesupport' element={<ResSupport />} />
        <Route path='itconsulting' element={<Consulting/> }/>
        <Route path='thedifference' element={<TheDif />} />
        <Route path='contact' element={<Contact/> }/>
      </Routes>


        
    </div>
  );
}

export default App;

      