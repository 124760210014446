import React from 'react'
import './Contact.css'
import Header from '../../components/Header/Header'
import Nav from '../../components/Nav/Nav'
import Calendly from '../../components/Calendly/Calendly'
import Footer from '../../components/Footer/Footer'

function Contact() {
  return (
    <div>
      <Header />
      <Nav />
      <div className="contact-body">
     
      </div>
      <Calendly />
      <Footer/>
    </div>
  )
}

export default Contact