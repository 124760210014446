import React from 'react'
import Header from '../../components/Header/Header'
import Nav from '../../components/Nav/Nav'
import Footer from '../../components/Footer/Footer'
import './TheDif.css'

function TheDif() {
  return (
    <div>
      <Header />
      <Nav />
      <div className="dif-body">
        <div className="diftitle-header">
        AN ORCHESTRATED DIFFERENCE
        </div>
        <div className="dif-textbox">


        Symphony IT was founded in 2023 by David,
        an IT professional with nearly a decade experience in a wide range of IT roles in San Francisco, California.
        Through his own dedicated research and learning regimens, David developed skills in Linux, Windows, macOS environments on an enterprise scale,
        using his skills to help friends, family and local non-profit & community organizations outside of his regular employment.

        Years later, David had realized an over-reliance on a few select software and cloud vendors in IT and other industries,
        despite the wide range of options available to business consumers. The selection of these legacy vendors is a symptom of the level of comfort
        and profitability in reselling products for Managed Service Providers, not the ease of use or meeting needs of clients and a modern security landscape.

        David has tested many varying technologies to support enterprise infrastructure, endpoints, and SaaS/IaaS needs for the modern era. His approach is to
        understand what you are currently doing (inputs & outputs of your systems), what is necessary, and where money can be saved, and developing individually-tailored
        solutions based on those needs.
        
        If you want a Managed Service Provider & PC repairman with empathy, respect and your needs at the forefront, then contact Symphony IT today for a free
        consultation so we can help to not only secure your environment and devices but save you money, as well.
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default TheDif